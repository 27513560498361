@use "../../Scss/Inheritance.scss";

.message_button {
  width: 100px;
  color: #FFFFFF;
  font-size: 15px;
font-weight: 400;
border-radius: 20px;
background: #009DFF;
  &:hover {
    color: #FFFFFF;
    background-color: #009DFF;
  }
}

.cancel_button {
  @extend %secondary_button;
  width: 100px;
font-size: 15px;
font-weight: 400;
border-radius: 20px;
margin-right: 10px;

// line-height: 31.25px;
text-align: left;
color:#585858

}
.boxofsubmitexam{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  border: 1px solid #BEBEBE;
  box-shadow: 0px 0px 8px 0px #00000014;
  flex-direction: column;

}
.concentofexam{
  width: 55%;
  padding: 20px;
  border: 1px solid #BEBEBE;
  box-shadow: 0px 0px 8px 0px #00000014;
 border-radius: 10px;

}
.submitexamheading{
width: 100%;
text-align: center;
font-size: 27px;
font-weight: 500;
line-height: 44.27px;
padding: 20px 20px 10px 20px ;
}

.iconfeedback{
  display:flex;
  gap:10px;
  width: 100%;
  justify-content: center;
  padding: 20px;
}
.pointer{
  cursor: pointer;
}

.skipheading{
  font-size: 17px;
font-weight: 500;
// line-height: 44.27px;
margin-top: 50px;
width: 10%;
padding: 7px;
border-radius: 10px;
cursor: pointer;
// padding: 20px 20px 10px 20px ;
}
.textalignfeedback{
  text-align: center;
}