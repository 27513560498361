@use "../../Scss/Inheritance.scss";

.messagebuttonsubmit {
  width: 100%;
  color: #FFFFFF;
  font-size: 15px;
font-weight: 400;
border-radius: 20px;
background: #009DFF;
margin-top: 10px;
text-transform: capitalize;
  &:hover {
    color: #FFFFFF;
    background-color: #009DFF;
  }
}

.cancel_button {
  @extend %secondary_button;
  width: 100px;
font-size: 15px;
font-weight: 400;
border-radius: 20px;
margin-right: 10px;

// line-height: 31.25px;
text-align: left;
color:#585858

}
.boxofsubmitexam{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  border: 1px solid #BEBEBE;
  box-shadow: 0px 0px 8px 0px #00000014;


}
.concentofexamsubmit{
  padding: 0px;
  border: 1px solid #BEBEBE;
  box-shadow: 0px 0px 8px 0px #00000014;
 border-radius: 10px;

}
.submitexamheading{
width: 100%;
text-align: center;
font-size: 27px;
font-weight: 500;
line-height: 44.27px;
padding: 20px 20px 10px 20px ;
}
.submitcomandline{
  border: 1px solid #D0D0D0;
   border-radius: 10px;
font-size: 17px;
font-weight: 400;
line-height: 23.44px;
text-align: center;
color: #5F5F5F;
margin: 0px 30px 30px 30px ;
padding: 10px;
}
.buttonfieldsubmit{
  padding: 30px;
  display:block;
  justify-content: center;
}
.chartlist{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.textchartexam{
font-size: 17px;
font-weight: 400;
line-height: 23.44px;
text-align: left;
color: #6B7280;
}
.chartexam{
  font-size: 18px;
  font-weight: 400;
  line-height: 23.44px;
  text-align: left;
  color: #6B7280;
  width: 50%;
  }
  .textchartexams{
    font-size: 17px;
    font-weight: 400;
    line-height: 23.44px;
    text-align: left;
    }