@use "../../Scss/Variables.module.scss" as I;

.chatbot_footer {
  .chat_input {
    .MuiOutlinedInput-root {
      background-color: #f5f5f5;
      border-radius: 25px;
    }
  }
}

.chat_bot_header {
  margin-top: 50px;
  margin-bottom: 5px;
  padding: 8px;
  color: I.$primary_color;
  display: flex;
  align-items: center;
font-size: 14px;
font-weight: 600;
line-height: 18.23px;
text-align: left;

}

.chat_bot_message {
    // background-color: rgba(183, 222, 171, 0.5);
    color: rgb(69, 162, 238);
  padding: 8px;
  margin: 4px;
  max-width: 330px;
  border-radius: 8px;
  text-align: left;
  p {
    margin: 0;
    font-size: 18px;


  }
}

.user_message {
  background-color: #f5f5f5;
  //   color: #0004ff;
  padding: 8px;
  margin: 4px;
  max-width: 330px;
  margin-left: auto;
  border-radius: 8px;
  p {
    margin: 0;
    font-size: 18px;
  }
}

.message_area {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: scroll;
  font-size: 200px;
}
