@use "../../Scss/Inheritance.scss";

.form_content {
  @extend %dialog_form;
  margin-top: 7px;
}

.dialog_content {
  @extend %dialog;
}


.activename {
  padding: 5px;
  border-radius: 5px;
 font-weight: 600;
  &.active {
    color: green;
  }

  &.inactive {
    color: red;
  }
}
