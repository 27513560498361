.ExamHistorymain{

  height: 100vh; // Ensure the container takes full viewport height
  padding: 20px 10px; // Optional: Add some padding
  box-sizing: border-box;
  margin: 50px 8%;

  // Enable scrolling
  overflow-y: auto;

  // Scrollbar styling
  &::-webkit-scrollbar {
    display: block; // Ensure the scrollbar is displayed
    width: 8px; // Customize scrollbar width
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b9b9b9; // Color of the scrollbar thumb
    border-radius: 4px; // Rounded corners for the thumb
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #919191; // Darker thumb on hover
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; // Color of the scrollbar track
  }
}



.listexam{
font-size: 24px;
font-weight: 400;
line-height: 39px;
text-align: left;
padding-bottom: 10px;

}
.histroy{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width:100%
}

.customCardpendinghistory {
  width: 100%;
  padding: 20px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  // height: 14rem;
  border-radius: 10px;
  border: 1px solid #CBCBCB
}


.textContenthistory{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width:100%
}
.textsytle{
  font-size: 15px;
  font-weight: 400;
  text-transform: capitalize;

}
.textsytleexamlist{
  font-family: DM Sans;
font-size: 22px;
font-weight: 700;
line-height: 39px;
text-align: left;
  padding: 10px 0px 10px 0px;
  color:#009DFF;
  text-transform: capitalize;
}



.card-buttonsexamgood {
  padding: 3px 10px;
  background-color: #E7E7E7;
  color: #000000;
  border: none;
  border-radius: 5px;
  
  transition: background-color 0.3s ease;
  margin: 10px 0px;
  border: 1px solid #000000;;
}

.card-buttonexam {
  padding: 10px 30px;
  background-color: white;
  color: #009DFF;
  border: 1px solid #009DFF ;
  border-radius: 20px;
  transition: background-color 0.3s ease;
  margin: 10px 0px;
  cursor: pointer;
}

.card-buttonexamlist {
  padding: 10px 30px;
  background-color: #009DFF;
  color: white;
  border: none;
  border-radius: 20px;
  
  transition: background-color 0.3s ease;
  margin: 10px 0px;
  cursor: pointer;

}

.feedbackword{
  font-size: 17px;
  color: #009DFF;
  padding-right: 10px;
}
.EnrolledHistory{
  display: flex;
  align-items: center;
  // justify-content: space-between;
  width:100%
}
.Attempcount{
  color: #009DFF;
text-align: center;
margin-top: 20px;
}

.Attempcountexam{
  color: #009DFF;
text-align: center;
margin-left: 10px;
}

.PractiseExams{
  color: #009DFF;
    font-weight: bold;
    font-size: 20px;
}

.pexamsscrollbar {
  // overflow: scroll;
  height: 55%;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
  border-radius: 10px;
  padding: 10px;
 
  // Scrollbar styling
  &::-webkit-scrollbar {
    display: block !important; // Ensure the scrollbar is displayed
    width: 8px !important; // Customize scrollbar width
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b9b9b9 !important; // Color of the scrollbar thumb
    border-radius: 4px !important; // Rounded corners for the thumb
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #919191 !important; // Darker thumb on hover
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1 !important; // Color of the scrollbar track
  }

}

.pendingexamsscrobar {
  // overflow: scroll;
  height: 30%;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
  border-radius: 10px;
  padding: 10px;
 
  // Scrollbar styling
  &::-webkit-scrollbar {
    display: block !important; // Ensure the scrollbar is displayed
    width: 8px !important; // Customize scrollbar width
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b9b9b9 !important; // Color of the scrollbar thumb
    border-radius: 4px !important; // Rounded corners for the thumb
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #919191 !important; // Darker thumb on hover
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1 !important; // Color of the scrollbar track
  }

}