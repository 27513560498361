@use "../../Scss/Inheritance.scss";


.form_contents {
  display: grid;
  grid-template-columns: repeat(2, 2fr);
  gap: 1rem; // Adjusts spacing between elements

  .form_save_button {
    grid-column: span 2; // Makes the button span across both columns
    justify-self: center; // Centers the button horizontally
    margin-top: 1rem;
  }
}

.boxofUserProfileuser {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  border: 1px solid #BEBEBE;
  box-shadow: 0px 0px 8px 0px #00000014;
}

.concentofexamuser {
  width: 50%;
  padding: 20px;
  border: 1px solid #BEBEBE;
  box-shadow: 0px 0px 8px 0px #00000014;
  border-radius: 10px;
}

.UserProfileheadinguser {
  width: 100%;
  text-align: center;
  font-size: 25px;
  font-weight: 500;
  line-height: 44.27px;
  padding: 20px 20px 10px 20px;
  color: #009DFF;
}

.save_button{
  background-color: #009DFF;
  width: 100%;
  display: flex;
  color:white;
  justify-self: center; // Centers the button horizontally
  margin-top: 3rem;
  font-size: 15px;
  width: 100%;

}
.save_button:hover{
  background-color: white;
  border: 1px solid #009DFF ;
  color: #009DFF;
  width: 100%;
  display: flex;
  justify-self: center; // Centers the button horizontally
  margin-top: 3rem;
  font-size: 15px;

}