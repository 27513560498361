@use "../../Scss/Inheritance.scss";
@use "../../Scss/Variables.module.scss" as V;

.alter_button {
  width: 100px;
  color: #FFFFFF;
  font-size: 13px;
font-weight: 400;
background: #009DFF;
  &:hover {
    color: #FFFFFF;
    background-color: #009DFF;
  }
}

.cancel_button {
  @extend %secondary_button;
  width: 100px;
font-size: 13px;
font-weight: 400;
// line-height: 31.25px;
text-align: left;
color:#585858

}

.alter_dialog_action {
  @extend %dialog_action;
}

.alter_dialog {
  @extend %dialog;
}

.alter_dialog_title {
  text-align: center;
  display: flex;
  justify-content: center;
  color: #000000;
}

.alter_dialog_content {
  text-align: justify;
  padding-block: 26px;
}

.response_button {
  @extend %primery_button;
  width: 80px;
}
