@use "../../Scss/Variables.module.scss" as v;

.backGround {
  display: none;
 }

.mainRegisration {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-inline: 24px;
  flex-shrink: 0;
  width: 100%;
  border-radius: 20px;
  backdrop-filter: blur(6px);
  hr {
    background: whitesmoke;
  }
}
.mainforgotpassword {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-inline: 24px;
  flex-shrink: 0;
  width: 100%;
  border-radius: 20px;
  // border: 2px solid rgba(119, 154, 249, 0.52);
  backdrop-filter: blur(6px);
  padding: 25px;
  hr {
    background: whitesmoke;
  }
}

.heading {
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 7px;
  display: flex;
  justify-content: center;
  cursor: pointer;
}

.subHeadingforgotpassword {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  padding: 0px 0px 20px 0px;

}
.subHeadingaccount {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  padding: 0px 0px 5px 0px;
}

.subHeading > a {
  color: #bbc6d8;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.textFields {
  display: flex;
  flex-direction: column;
  gap: 13px;
}

.textField {
  width: 100%;
}

.textField > div {
  border-radius: 5px;
  background: #fff;
  border-color: #eee;
  height: 40px;
}

.buttonfield {
  border-radius: 100px;
  height: 40px;
  background: v.$primary_color;
  width: 100%;
  color: white;
  margin: 10px 0px 10px 0px;

}

.buttonfield:hover {
  background-color: v.$primary_color;
}

.forgetPassword {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  text-align: center;
}
.forgetPasswordfield {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  text-align: center;
}
.login_page {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

/* Style for larger screens */
.parent-div {
  display: flex;
  height: 100%;
  width: 100%;
}

.child-div1 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 60%;
  background-image: url(../../Assets/Images/cartoon-ai-robot-scene.jpg);
  background-position: 10%;
  background-size: cover;
  @media (max-width: v.$phone) and (orientation: landscape) {
    align-items: flex-start;
    overflow: auto;
  }
}

.child-div2 {
  width: 40%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px; /* Optional: Add padding for better spacing */
  box-sizing: border-box; /* Ensure padding does not affect width calculation */
}

/* Responsive adjustments for mobile devices */
@media (max-width: 800px) {
  .parent-div{
    display: none;

  }
  .backGround {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    color: white;
    // background: linear-gradient(180deg, #0574e4 0%, #022180 100%);
    background-image: url(../../Assets/Images/background_image.svg);
    background-position: 10%;
    background-size: cover;
    @media (max-width: v.$phone) and (orientation: landscape) {
      align-items: flex-start;
      overflow: auto;
    }
  }
 
}

.emailplaceholder{
  margin-bottom: 10px;
}
.passwordplaceholder{
  margin-bottom: 10px;

}