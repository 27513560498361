@use "./Scss/Inheritance.scss";
@use "./Scss/Variables.module.scss" as V;

/* Initial setup css: */

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "DM Sans", sans-serif !important;
  font-size: 12px;
  /*    remove scroll bars     */
  // -ms-overflow-style: none; /* IE 11 */
  // scrollbar-width: none; /* Firefox 64 */
}
*::-webkit-scrollbar {
  display: none;
}
.App {
  height: 100%;
}

#root {
  height: 100%;
}

// /* Scroll bar css: */
// /* width */
// ::-webkit-scrollbar {
//   width: 0;
//   height: 0.6em;
// }

// /* Track */
// ::-webkit-scrollbar-track {
//   box-shadow: inset 0 0 3px rgb(213, 213, 213);
//   border-radius: 10px;
//   margin: 1em;
// }

// /* Handle */
// ::-webkit-scrollbar-thumb {
//   background: #f5f5f5;
//   border-radius: 10px;
//   border: 1px solid rgb(213, 213, 213);
// }

// /* Handle on hover */
// ::-webkit-scrollbar-thumb:hover {
//   background: black;
// }

* {
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "DM Sans", sans-serif !important;
  font-size: 12px;
}

/* WebKit browsers scrollbar styles */
*::-webkit-scrollbar {
  width: 7px; /* Adjust width */
  height: 8px; /* Adjust height */
  z-index: -1000;
}

/* Track */
*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px rgb(213, 213, 213);
  border-radius: 10px;
  z-index: -1000;

}

/* Handle */
*::-webkit-scrollbar-thumb {
  background: #f5f5f5;
  border-radius: 10px;
  border: 1px solid rgb(213, 213, 213);
  z-index: -1000;

}

/* Handle hover */
*::-webkit-scrollbar-thumb:hover {
  background: rgb(219, 230, 240);
  z-index: -1000;

}

/* For Firefox */
* {
  // scrollbar-width: auto; /* Default scrollbar width */
  // scrollbar-color: #9e9c9c transparent; /* Thumb and track colors */
}

/* Remove autofill background color: */
/*    remove autofill background color     */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, color 5000s;
}

section {
  display: inline-flex;
  gap: 1rem;
  overflow: auto;
  height: 100%;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  flex-wrap: wrap;
  align-items: center;
}

// mui data grid cell
.MuiDataGrid-cell {
  padding: 4px !important;
}

.tabel_add_new_button {
  @extend %primery_button;
  // margin-left: auto;
}
.tabel_import_item_button {
  @extend %primery_button;
}

.form_cancel_button {
  @extend %secondary_button;
  max-width: 100px;
  width: 100%;
}

.form_save_button {
  @extend %primery_button;
  max-width: 100px;
  width: 100%;
}

.dialog {
  @extend %dialog;
}
.dialog_large {
  @extend %dialog_large;
}
.dialog_mid_large{
  min-width: 100px;
}
.dialog_double_input {
  @extend %dialog_double_input;
}

.form_double_input {
  @extend %dialog_form_double_input;
  margin-top: 10px;
}
.stock_item_tab_form {
  display: flex;
  gap: 20px;
}
.custom_toolbar {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.custom_toolbar_search_add_new {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}
.new_ui_form_single {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  .form_field_first_line {
    width: 100%;
    display: flex;
    justify-content: space-between;

    .form_start_fields {
      width: 25%;
      display: flex;
      gap: 15px;
    }
    .form_end_fields {
      width: 20%;
      display: flex;
      gap: 15px;
    }
  }
  .form_field_second_line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .form_start_fields {
      width: 25%;
      display: flex;
      gap: 15px;
    }
    .form_end_fields {
      width: 20%;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
  .form_field_third_line {
    width: 100%;
    display: flex;
    justify-content: space-between;
    .form_start_fields {
      width: 25%;
      display: flex;
      gap: 15px;
    }
    .form_end_fields {
      width: 20%;
      display: flex;
      flex-direction: column;
      gap: 15px;
    }
  }
}

// table toolbar form
.toolbar_form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  gap: inherit;
  .left_div {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(1, minmax(100px, 300px));
    justify-content: flex-start;
    height: 100%;
    gap: inherit;
  }
  .right_div {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(1, minmax(100px, 300px));
    justify-content: flex-end;
    height: 100%;
    gap: inherit;
  }
}

// tabel cell editable cell background color
.inputCell {
  background-color: V.$background_color;
  border-color: V.$primary_color !important;
}
