@use "../../Scss/Inheritance.scss";

.listexam{
  display: flex;
  justify-content: space-between;
 padding: 10px;


}
.listexamword{
  font-size: 13px;
  font-weight: 400;
  line-height: 26.04px;
}
.listexamwordtime{
  font-size: 11px;
  font-weight: 400;
  line-height: 26.04px;
}
.examdivpage {
  // Optional: Ensure the container takes full viewport height
  height: 100vh;

  padding: 20px 10px; // Add padding
  box-sizing: border-box;
  // margin: 50px 8%;

  // Enable scrolling
  overflow-y: auto;

  // Scrollbar styling
  &::-webkit-scrollbar {
    display: block; // Ensure the scrollbar is displayed
    width: 8px; // Customize scrollbar width
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b9b9b9; // Color of the scrollbar thumb
    border-radius: 4px; // Rounded corners for the thumb
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #919191; // Darker thumb on hover
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; // Color of the scrollbar track
  }
}

.examdivpagewriting {
  // Optional: Ensure the container takes full viewport height
  height: 100vh;

  padding: 20px 10px; // Add padding
  box-sizing: border-box;
  // margin: 50px 8%;

  // Enable scrolling
  overflow-y: auto;

  // Scrollbar styling
  &::-webkit-scrollbar {
    display: block; // Ensure the scrollbar is displayed
    width: 8px; // Customize scrollbar width
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b9b9b9; // Color of the scrollbar thumb
    border-radius: 4px; // Rounded corners for the thumb
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #919191; // Darker thumb on hover
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1; // Color of the scrollbar track
  }
}
.examResultdiv{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  gap: 20px;
}
.rightdiv{
  border: 1px solid #CACACA;
  border-radius: 5px;
  width: 75%;
  
}
.leftdiv{
  border: 1px solid #CACACA;
  border-radius: 5px;
  width: 25%;
text-align: center;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .examResultdiv {
    display: inline-block;
    margin-top: 10px;
    gap: 20px;
    width: 100%;
    /* Add any specific styling adjustments for tablet screens here */
  }
  .leftdiv{
    border: 1px solid #CACACA;
    border-radius: 5px;
    width: 100%;
  text-align: center;
  margin-bottom: 10px;
  }
 
  .rightdiv{
    border: 1px solid #CACACA;
    border-radius: 5px;
    width: 100%;
    
  }

}




.yourscore{
font-size: 18px;
font-weight: 500;
text-align: center;
padding: 12px;

}
.timesepent{
  font-size: 22px;
  font-weight: 500;
  text-align: center;
  padding: 12px;
  
  }
.headingexam{
font-size: 27px;
font-weight: 500;
line-height: 39px;

}

.reslutvalue{
font-size: 27px;
font-weight: 500;
text-align: center;

}
.resultcount{
font-size: 17px;
font-weight: 500;
line-height: 31.25px;
text-align: center;
color: #727272;

}
.messagebutton {
  width: 90%;
  margin-bottom: 10px;
  color: #FFFFFF;
  font-size: 15px;
font-weight: 400;
border-radius: 20px;
background: #009DFF;
  &:hover {
    color: #FFFFFF;
    background-color: #009DFF;
  }
}
.messagebuttons {
  color: #FFFFFF;
  font-size: 15px;
font-weight: 400;
border-radius: 20px;
margin-top: 7%;
margin-right: 10px;

background: #009DFF;
  &:hover {
    color: #FFFFFF;
    background-color: #009DFF;
  }
}
.secondwriting{
  width: 50%;
  text-align: end;
  margin-right: 7%;
  gap: 10px;
  margin-top: 4%;

}

.headingtotal{
font-size: 19px;
font-weight: 500;
line-height: 31.25px;
text-align: left;
padding: 10px;

}

.exampargarph{
font-size: 17px;
font-weight: 400;
line-height: 28px;
text-align: left;
margin: 10px;

}
.correctvalue{
font-size: 19px;
font-weight: 400;
line-height: 31.25px;
text-align: center;
color: #242424;
margin: -10px;
}
.questionvalue{
  font-size: 27px;
  font-weight: 400;
  line-height: 31.25px;
  text-align: center;
  color: rgb(183 183 183);;
  }
  .answervalue{
    font-size: 2.2rem;
    font-weight: 600;
    line-height: 31.25px;
    text-align: center;
    color: rgb(20, 18, 18);
    }

.exmaresult_cards_container {
  gap: 16px; /* Adjust spacing between items */
  display: flex;
  flex-wrap: wrap;

}


.Examresult_cards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // padding: 8px;
  // min-height: 240px;
}

.Examresult_name {
  font-size: 18px;
  font-weight: 600;
  height: 50px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  background-size: cover;

}

.Examlist_card {
 
  background-color: #fbfbfb;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
  width: 230px;
}
.examresultpage{
  font-size: 27px;
font-weight: 500;
line-height: 39px;
margin: 30px 0px;
}

.examresultcorrect{
  text-align: center;
    width: 100%;
    padding: 25px 10px 0px 10px;
    font-size: 2.7rem;
    text-transform: capitalize;
    font-weight: 600;
}
.examcountout{
  text-align: center;
  font-size: 1.8rem;
  font-weight: 600;
  color: #CACACA;
  padding: 0px 0px 20px 0px;

}

.examlistdiv{
  border: 1px solid #CACACA;
  border-radius: 5px;
  width: 100%;
  display: flex;
    justify-content: space-between;
    padding: 30px 0px 30px 0px;
}
.scorelist{
  margin: 30px 0px;
}
.performchart{
  display: flex;
  justify-content: space-between;
}

// writing  table 
/* Outer container with padding for spacing */
.writing{
  font-size: 27px;
font-weight: 500;
line-height: 39px;
margin-top: 7%;
color: #009DFF;
margin-left: 8%;
}
.responsive-table-container {
  margin: 1% 8% 3% 8%; /* Additional space from the edges */
  background-color: #f9f9f9; /* Subtle background for better visibility */
  border-radius: 12px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow for aesthetic */
}

/* Table styling */
.responsive-table {
  width: 100%;
  border-collapse: collapse;
  display: table;
  border: 1px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
}

.table-row{
  width: 100%;
  display: flex;
  border-bottom: 1px solid #d0c6c6
}
/* Header row */
.table-row.header {
  background: #f0f0f0;
  font-weight: bold;
  border-bottom: 2px solid #ddd;
  width: 100%;
  display: flex;
  justify-content:space-around
}
.tableand{
  display:block;
  margin-top: 15px;
}

/* Individual table cells */
.table-cell {
  display: table-cell;
  padding: 16px;
  vertical-align: top;

}

.section-cell {
  width: 50%;
}

.feedback-cell {
  width: 50%;
}

.section-cell h3 {
  margin: 0 0 10px;
  font-size: 16px;
  color: #333;
}

.feedback-cell p {
  margin: 0;
  line-height: 1.5;
  font-size: 14px;
  color: #555;
}

/* Responsive design for tablets and smaller screens */
@media (max-width: 1024px) {
  

  .responsive-table {
    display: block;
  }

  .table-row {
    display: block;
    margin-bottom: 16px;
  }
 
  .tableand{
    display: none;
    }

  

  .table-cell {
    display: block;
    width: 100%;
    padding: 12px;
    border-bottom: 0;
  }

  .section-cell h3 {
    font-size: 14px;
  }

  .feedback-cell p {
    font-size: 12px;
  }
}

/* Mobile-first design */
@media (max-width: 768px) {


  .table-row {
    margin-bottom: 12px;
    border: none

  }

  .table-cell {
    padding: 10px;
  }
}

.tabledatafount{
  padding: 20%;
  text-align: center;
  width: 100%;
}