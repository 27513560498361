@use "../../Scss/Inheritance.scss" as I;
@import "../../Scss/Variables.module.scss";
@import "../../Scss/Functions.scss";

$list_button_hover: hover_color($primary_color);

.app_bar {
  .MuiToolbar-root {
    justify-content: flex-end;
    min-height: 52px;
    padding: 8px;
    // background-color: $mode;
  }
}

.mainSection {
  // background-color: $background_color;
  padding: 8px;
  padding-top: 12px;
}

.listText {
  color: inherit;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
}

.activeMenu {
  color: $secondary_color !important;
  background-color: $primary_color !important;
  .MuiListItemIcon-root {
    color: inherit;
  }
  .MuiSvgIcon-root {
    fill: transparent !important;
    stroke: $secondary_color !important;
  }
  .listText {
    color: $secondary_color !important;
    font-weight: 600 !important;
  }
  &:hover {
    color: $text_color !important;
    background-color: $primary_color !important;
  }
  &:focus-visible {
    background-color: $secondary_color;
  }
}

.activeSubMenu {
  color: $secondary_color !important;
  // background-color: $background_color;
  .listText {
    color: $primary_color;
    font-weight: 600 !important;
  }
  .MuiSvgIcon-root {
    fill: transparent !important;
    stroke: $primary_color !important;
  }
  .MuiListItemIcon-root {
    color: inherit;
  }
  &:hover {
    color: $text_color !important;
    background-color: $list_button_hover !important;
  }
  &:focus-visible {
    background-color: $secondary_color;
  }
}

.listButton {
  margin: 2px 8px;
  display: flex;
  padding: 4px 8px 4px 12px;
  align-items: center;
  border-radius: 8px;
  // color: $text_color;
  div {
    color: inherit;
  }
  .listButton.Mui-selected .MuiListItemIcon-root {
    /* Styles for selected state */
    color: inherit;
  }
  .listButton.Mui-selected .listText {
    /* Styles for selected state */
    font-weight: 600 !important;
  }
  .listButton.Mui-selected {
    /* Styles for selected state */
    color: inherit !important;
    background-color: rgba(230, 236, 255, 1);
  }
  .MuiSvgIcon-root {
    fill: transparent;
    // fill: $text_color;
    stroke: currentColor;
  }
  &:hover {
    background-color: $list_button_hover;
  }
}

.miniListButton {
  border-radius: 8px;
  color: $text_color;
  padding-bottom: unset;
  margin: 2px;
  div {
    color: inherit;
  }
  .miniListButton.Mui-selected .MuiListItemIcon-root {
    /* Styles for selected state */
    color: inherit;
  }
  .miniListButton.Mui-selected .listText {
    /* Styles for selected state */
    font-weight: 600 !important;
  }
  .miniListButton.Mui-selected {
    /* Styles for selected state */
    color: inherit !important;
    background-color: rgba(230, 236, 255, 1);
  }
  .MuiSvgIcon-root {
    fill: transparent;
    stroke: $text_color;
  }
  &:hover {
    background-color: $list_button_hover;
  }
}

.listSubHeader {
  // font-size: 12px;
  font-weight: 700;
  letter-spacing: 0em;
  text-align: left;
  padding-left: 35px;
  text-transform: uppercase;
  color: $text_color;
  // background-color: $secondary_color;
  background-color: #f4f6f8;
}

.navigate_icon_button {
  color: $primary_color;
  background-color: $secondary_color;
  &:hover {
    color: $primary_color;
    background-color: $secondary_color;
  }
  &:first-child {
    margin-right: auto;
  }
}

.drawer_header {
  display: flex;
  justify-content: center;
  width: 100%;
  align-items: center;
  min-height: 52px;
}

.svg_icon {
  @extend %svg_icon;
  stroke-width: 0;
}

.appbar_icon {
  fill: transparent;
  stroke: $secondary_color;
  &:hover {
    fill: transparent; // stroke: $primary_color;
    background: $list_button_hover;
  }
  .MuiSvgIcon-root {
    fill: transparent;
    stroke: inherit;
  }
}

.app_bar_user_details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  // color: $text_color;
  .name {
    text-align: center;
  }
}
