@use "../../Scss/Inheritance.scss";

.form_content {
  @extend %dialog_form;
  margin-top: 7px;
}

.dialog_content {
  @extend %dialog;
}
.active_Btn{
  border: none;
  border-color: none;
}

.MuiButtonGroup-root{
  border-color: white !important;
}