/* .GlobalInputStyle {
  width: 50%;
} */

/* .GlobalInputLableStyle {
  color: black;
} */

/* .GlobalAutoCompleteStyle{
    
} */

.truncate-option {
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden;    /* Hides text overflow */
  text-overflow: ellipsis; /* Adds ellipsis when text overflows */
  max-width: 200px; /* Adjust the max width as needed */
}
