@use "../../Scss/Inheritance.scss";

.form_content {
  @extend %dialog_form;
  margin-top: 7px;
}

.dialog_content {
  @extend %dialog;
}

.instruction-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 10px; 
  box-sizing: border-box;
  margin: 50px 8%;
 
}

// Media queries for mobile responsiveness
@media (max-width: 768px) {
  .instruction-container {
    padding: 10px;
  }
}

// Media queries for smaller tablets and landscape phones
@media (max-width: 480px) {
  .instruction-container {
    padding: 5px;
  }
}


.subscribedpagedev {
  display: flex;
  width: 100%;
  gap: 10px;
}

.Subscribedpageone {
  width: 70%;
}

.Subscribedpagetwo {
  width: 30%;
}

/* Media query for small screens */
@media screen and (max-width: 768px) {
  .subscribedpagedev {
    flex-direction: column; /* Stack the two sections vertically */
  }

  .Subscribedpageone,
  .Subscribedpagetwo {
    width: 100%; /* Make both sections take full width */
  }
}
.courseslistsubscribed{
  font-size: 22px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
  color: #009DFF;
  text-transform: capitalize;
}
.courseslistsubscribedexam{
  font-size: 20px;
  font-weight: 500;
  padding: 10px 0px 10px 0px;
  color: #292828;
  text-transform: capitalize;
}
.descipationsubscribed{
  font-size: 18px;
  font-weight: 400;
  padding: 10px 0px 10px 0px;
  text-transform: capitalize;
}

.customCardpendingsubscribed {
  width: 100%;
  padding: 10px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  border-radius: 10px;
  border: 1px solid #CBCBCB
}
.textContentsubscribed{
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width:100%
}
.concentnamesub{
  text-align: left;
  width: 100%;
  padding:10px;
  text-transform: capitalize;
  font-weight: 400;
  display: flex;
  align-items: center;
  gap:5px
}
.costoflistsub{
  text-align: left;
  width: 100%;
  padding: 4px 10px 1px 10px;
  font-size: 22px;
  text-transform: capitalize;
  font-weight: 600;
}
.card-buttonlistsub {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 0px;
  width: 95%;
}

.card-buttonlistsub:hover {
  background-color: #009DFF;
}

.examlistcard{
  padding: 10px;
  border-bottom: 1px solid #009DFF;
  border-radius: 0px;
  margin-bottom: 5px;
}

.sytleheading {
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0px 0px 0px;
  text-transform: capitalize;
  width: 100%;

}
.examheading{
  font-size: 20px;
  font-weight: 600;
  text-transform: capitalize;
  width: 100%;
}