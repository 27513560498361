@import '../../Scss/Inheritance.scss';

.response_icon{
    width: 100%;
  height: 100%;
  fill: transparent;
  max-height: 120px;
  max-width: 183px;
}

.response_content{
    text-align: center;
    display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  //   font-size: 14px;
  // font-weight: 400;
  // line-height: 24px;
}

.response_ok_btn{
  @extend %primery_button;
  width: 80px;
}