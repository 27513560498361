@import "./Variables.module.scss";

/* Buttons */
// primery button
%primery_button {
  color: $secondary_color;
  background-color: $primary_color;
  border: 1px solid $primary_color;
  &:hover {
    color: $secondary_color;
    background-color: $primary_color;
    border: 1px solid $primary_color;
  }
}

// secondary button
%secondary_button {
  color: $primary_color;
  background-color: $secondary_color;
  border: 1px solid $primary_color;
  &:hover {
    color: $primary_color;
    background-color: $secondary_color;
    border: 1px solid $primary_color;
  }
}

// page container
%page_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  height: calc(100vh - 111px);
  header {
    display: flex;
    align-items: center;
    h6 {
      padding: 0;
      margin: 0;
      font-size: 1.3rem;
      font-weight: 700;
    }
    p {
      margin: 0;
      padding: 0;
      font-weight: 400;
      font-size: 14px;
    }
  }
  section {
    display: contents;
  }
}

// Table header mui buttons ( print, csv)
%table_print_csv_buttons {
  @extend %primery_button;
  border-radius: 10px;
  li {
    // font-size: 14px;
    height: 100%;
  }
}

// svg icon
%svg_icon {
  fill: $primary_color;
  stroke: $primary_color;
}

// form dialog
%dialog {
  max-width: 321px;
  width: 100%;
}
%dialog_double_input {
  max-width: 616px;
  width: 100%;
}
%dialog_large {
  height: 100%;
  display: flex;
  max-width: 60dvw;
  width: 100%;
}
%dialog_title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
%dialog_form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

%dialog_form_double_input {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(234px, 1fr));
  gap: 20px;
}
%dialog_action {
  padding-bottom: 16px;
  gap: 8px;
}
