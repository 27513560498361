
  .onlineexam{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 50px 8% 10px 8%;
    padding: 20px 0px 0px 0px; // Optional: Add some padding
  }
  .overonlinediv{
    margin: 0px 8% 10px 8%;
    padding: 15px 0px; // Optional: Add some padding
    height: 600px;
    // overflow:scroll;
  }

  .onlineexmcount{
font-size: 15px;
font-weight: 600;
line-height: 39px;
text-align: left;
color: #FFFFFF;


  }
  .onlineexamtime{
    background-color: #FFFFFF;
    border: 1px solid #DDDDDD;
    color: #BB3695;
   padding: 7px;
   border-radius: 10px;
   width: 65px;
   text-align: center;
   border-radius: 10px;
  }

  .onlineexamdiv{
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: linear-gradient(89.74deg, #017CC9 24.39%, rgba(0, 157, 255, 0.49) 120.36%);
  height: 50px;
  padding: 0px 8%;

    
  }
  .timer {
    text-align: right;
  }
  
  .navigation-buttons {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
   
  }
  
  @media (max-width: 768px) {
    .timer {
      text-align: center;
    }
  }
  
  .examtext{
font-size: 23px;
font-weight: 500;
line-height: 39px;
text-align: left;
text-transform: capitalize;

  }

  .buttonnamenextresport{
font-size: 14px;
font-weight: 500;
line-height: 31.25px;
text-align: left;
color: #FFFFFF;
background: #009DFF;
border-radius: 20px;
height: 35px;
text-transform: capitalize;
width: 200px;

  }
  .buttonnamenextresport:hover{
    background: #009DFF;
  }
  .buttonnamenext{
    font-size: 14px;
    font-weight: 500;
    line-height: 31.25px;
    text-align: left;
    color: #FFFFFF;
    background: #009DFF;
    border-radius: 20px;
    height: 35px;
    text-transform: capitalize;
    width: 100px;
    
      }
      .buttonnamenext:hover{
        background: #009DFF;
      }
  .buttonnameprevious{
font-size: 14px;
font-weight: 500;
line-height: 31.25px;
text-align: left;
border: 1px solid #009DFF;
color: #009DFF;
border-radius: 20px;
text-transform: capitalize;
height: 35px;
width: 100px;
  }

  .navigation-wrapper {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-image: url("../../Assets/Images/examquestionimage.svg");
    padding: 0px 8% 20px 8%;
    height: 564px;
    z-index: -1; /* Keep the image behind */
}


.navigation-buttonsre {
    position: fixed; /* or absolute if within a container */
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0px 8% 20px 8%;
    justify-content: flex-end; /* Adjusts spacing between buttons */
    z-index: 999; /* Ensure it's above other elements */
  }
  
  .unselectable {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  .Questiontype{
    display: flex;
    align-items: center;
    color: #FFFFFF;
   
  }
  .Questiontypeheading{
    font-size: 20px;
    margin-right: 10px;
  }

  .buttonsection{
  color: white;
  background-color: #BB3695;
  text-transform: capitalize;
  height: 33px;
}

.buttonsection:hover{
    background-color: #BB3695;

}
.boxDialog{
font-size: 24px;
font-weight: 500;
line-height: 39px;
text-align: left;
 color: #009DFF;
}

.boxDialogparapgraph{
    font-size: 17px;
    font-weight: 500;
    text-align: left;
     padding-top: 20px;
    }

    .listissection{
        padding: 25px;
        
    }
    .listtype{
        font-size: 17px;
        font-weight: 500;
        text-align: left;
        line-height: 50px;
    }




    .ai-button {
      text-decoration: none;
      background: linear-gradient(to right, #008FE8, #009DFF); /* Gradient from #008FE8 to #009DFF */
      border: 0px solid white;
      color: aliceblue;
      border-radius: 100%;
      padding: 0px;
      font-family: sans-serif;
      font-size: 16px;
      font-weight: bold;
      cursor: pointer;
      position: fixed;
     margin-top: 10px;
      z-index: 999;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-left: 50px;
  }

  .ai-button img {
      width: 24px; /* Adjust the size of the image */
      height: 24px; /* Match the width for a square icon */
  }

  .ai-button::after {
      position: absolute;
      content: '';
      bottom: -7px;
      top: -7px;
      left: -7px;
      right: -7px;
      background-color: transparent;
      border-radius: 50%;
      border: 4px solid #009DFF;
      margin: 0px;
      opacity: 1;
      transform-origin: center;
      animation: anim 3000ms linear infinite;
  }

  .ai-button::before {
      position: absolute;
      content: '';
      bottom: -7px;
      top: -7px;
      left: -7px;
      right: -7px;
      background-color: transparent;
      border-radius: 50%;
      border: 4px solid #009DFF;
      margin: 0px;
      opacity: 0.8;
      transform-origin: center;
      animation: anim 800ms linear 400ms infinite;
  }

  @keyframes anim {
      from {
          margin: 0px;
          opacity: 1;
      }
      to {
          margin: -15px;
          opacity: 0;
      }
  }

  .disabled-button {
    background: #B0B0B0 !important; /* Gray background */
    color: #E0E0E0 !important; /* Light gray text */
    cursor: not-allowed !important;
    border: 0px solid white;
  }
  
  // .disabled-button::after,
  // .disabled-button::before {
  //   display: none !important; /* Remove animations */
  // }
  