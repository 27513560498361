@use "../../Scss/Inheritance.scss";

.form_content {
  @extend %dialog_form;
  margin-top: 7px;
  max-width: 400px;

}

.dialog_content {
  @extend %dialog;
}

.twofieldlist{
  display: flex;
  gap: 10px;
  align-items: center;
}

.Altermeassge{
  display: flex;
  align-items: center;
}