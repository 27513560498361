@use "../../Scss/Variables.module.scss" as V;

.table_search {
  .MuiFilledInput-input {
    padding-top: 8px;
  }
  padding: 0;
}

.Table {
  // background-color: V.$secondary_color;
  // background-color: V.$mode;
  border-radius: 8px;
  min-height: 450px;
  .MuiDataGrid-columnHeaders {
    background-color: #f4f6f8;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    min-height: 40px !important;
    max-height: 40px !important;
  }
  .MuiMenu-paper {
    max-height: 200px !important;
  }
}

.toolbar {
  padding: 1rem;
  gap: 1rem;
  h6 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 28px;
    text-align: left;
    margin: 0;
    margin-right: auto;
  }
}

.custom_no_rows {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #aaa;
}

.table_svg_icon {
  // @extend  %svg_icon;
  fill: transparent;
  // stroke: $secondary_color;
  height: 188px;
  width: 236px;
}
