.DashboardUsermain {
  height: 100vh; // Ensure the container takes full viewport height
  padding: 20px 10px; // Optional: Add some padding
  box-sizing: border-box;
  margin: 50px 8%;
}
/* Container for the entire dashboard section */
.dashboard_container {
  padding: 40px;
  background-color: #f9f9f9;
  text-align: center;
}

/* Dashboard heading */
.dashboard_heading {
  font-size: 2.7rem;
  margin-bottom: 30px;
  color: #333;
}

.dashboard_cards_container {
  display: flex;
  gap: 16px; /* Space between cards */
  overflow-x: auto; /* Enable horizontal scrolling */
  padding: 13px; /* Optional: Add padding if needed */
  scroll-snap-type: x mandatory; /* Snap scroll behavior for smooth scrolling */
  -webkit-overflow-scrolling: touch; /* Smooth scrolling on iOS */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
border-radius: 10px;
flex-wrap: wrap;
}

.dashboard_card {
  flex: 0 0 calc(25% - 16px); /* Show 4 cards per row by setting each to 25% width */
  min-width: 250px; /* Ensures cards don’t get too small */
  max-width: 250px; /* Limit the max width of each card */

  background-color: #fbfbfb;
  border: 1px solid #f5f5f5;
  border-radius: 8px;
  padding: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}

.heading_name {
  font-size: 1.27rem;
  color: white;
  font-weight: 600;
  background-color: #55a7ff;
  width: 95%;
  height: 105px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  background-image: url(../../Assets/Images/Card.svg);
  background-size: cover;
  padding-left: 10px;
}
.questioncount{
  display: flex;
  justify-content:flex-end;
}
.spanlist{
  background: linear-gradient(#008FE8,#009DFF);
  color: white;
  border-radius: 15px;
  width: 25px;

}
.heading_namelist {
  font-size: 1.27rem;
  // color: white;
  font-weight: 600;
  background-color: #cde2f8;
  width: 100%;
  border-radius: 10px;
  text-transform: capitalize;
  padding: 5px;
}
.timeandpercentage{
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  height: 30px;
  align-items: center;
}
/* Hover effect for cards */
.dashboard_card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Card title */
.dashboard_card h2 {
  font-size: 1.7rem;
  margin-bottom: 10px;
  color: #555;
}

/* Card description */
.dashboard_card p {
  font-size: 1.2rem;
  color: #777;
}

/* Responsive Design */
@media (max-width: 768px) {
  .dashboard_cards_container {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    display: flex;
    gap: 16px; /* Space between cards */
    justify-content: center; /* Center the cards horizontally */

  }

  // .dashboard_card {
  //   flex: 1 1 100%; /* Stack cards vertically on smaller screens */
  //   max-width: 400px; /* Optional: limit max width for better readability */
  // }
}

.courseslist {
  font-size: 19px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
  color: #292828;
}
.customCard {
  width: 100%;
  background: linear-gradient(#008FE8,#009DFF);
  padding: 20px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 12.5rem;
  border-radius: 10px;
}
.customCardpending {
  width: 100%;
  padding: 20px;
  display: flex;
  align-items: center;
  // height: 18rem;
  border-radius: 10px;

  background-color: #fbfbfb;
  border: 1px solid #f5f5f5;
}

.images {
  width: 150px;
  height: 140px;
  object-fit: cover;
  margin-top: -27px;
}
.imagesleftside {
  width: 150px;
  object-fit: cover;
}

.image {
  width: 120px;
  height: 121px;
  object-fit: cover;
}
.textContent {
  margin-left: 35px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.textsytles {
  font-size: 14px;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .textsytles {
    font-size: 11px;
    font-weight: 400;
  }
}

.textsytleheading {
  font-size: 19px;
  font-weight: 600;
  padding: 22px 0px 10px 0px;
  text-transform: capitalize;
  color:"white"
}
@media screen and (max-width: 768px) {
  .textsytleheading {
    font-size: 16px; /* Adjust font size for tablets */
    padding: 10px 0px 8px 0px; /* Adjust padding */
  }
}
// card
.mock-exam-container {
  text-align: center;
  padding: 20px 0px 10px 0px;
  width: 100%;
}

.mock-exam-heading {
  font-size: 2.2rem;
  margin-bottom: 30px;
  color: #333;
  text-align: left;
}

.cards-wrapper {
  display: flex;
  justify-content: center; /* Center the cards horizontally */
  gap: 20px;
  width: 100%; /* Space between the cards */
}

.card {
  background-image: url("../../Assets/Images/EnrolledUser.svg");
  /* Center and scale the image nicely */
  background-position: center;
  // background-repeat: no-repeat;
  background-size: cover;
  background-color: #fbfbfb;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  padding: 0 1rem;
}

.multi-card {
  background-color: #fbfbfb;
  border: 1px solid #e0e0e0;
  border-radius: 10px;
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  padding: 0 1rem;
  background-image: url("../../Assets/Images/Mockexamimage.svg");
  /* Full height */
  height: 100%;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.exam-history {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  background: transparent;
  width: 100%;
}

.mock-exam {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  background: transparent;
  width: 100%;
}

.multi-card:hover,
.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.card h2 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #555;
}

.card p {
  font-size: 1.2rem;
  color: #777;
}

.card-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 0px;
}

.card-button:hover {
  background-color: #0056b3;
}

.card-buttons {
  padding: 10px 20px;
  background-color: #e7e7e7;
  color: #000000;
  border: none;
  border-radius: 5px;

  transition: background-color 0.3s ease;
  margin: 10px 0px;
}

.card-button:hover {
  background-color: #0056b3;
  color: #fff;
}
.desudrised {
  width: 100%;
  text-align: left;
  margin-left: 10px;
}

.card-buttonlist {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 0px;
  width: 95%;
}

.card-buttonlist:hover {
  background-color: #0056b3;
}

.concentname {
  text-align: left;
  width: 100%;
  padding: 20px 10px;
  font-size: larger;
  text-transform: capitalize;
  font-weight: 600;
}
.examcount {
  text-align: left;
  width: 100%;
  padding: 10px 10px 0px 10px;
  font-size: larger;
  text-transform: capitalize;
  color: #635151;
}

.costoflist {
  text-align: left;
  width: 100%;
  padding: 4px 10px 1px 10px;
  font-size: larger;
  text-transform: capitalize;
  font-weight: 600;
}

.pendingexamsscrollbar {
  // overflow: scroll;
  // height: 30%;
  overflow-y: auto;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Shadow effect */
  border-radius: 10px;
  padding: 10px;
 
  // Scrollbar styling
  &::-webkit-scrollbar {
    display: block !important; // Ensure the scrollbar is displayed
    width: 8px !important; // Customize scrollbar width
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b9b9b9 !important; // Color of the scrollbar thumb
    border-radius: 4px !important; // Rounded corners for the thumb
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #919191 !important; // Darker thumb on hover
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1 !important; // Color of the scrollbar track
  }

}



// Home 
/* Main container with gradient background */
.welcome-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  padding: 1rem;
  max-width: 1200px;
  margin: 0 auto;
  color: white; /* Text color for better visibility */
}

/* Text section */
.welcome-text {
  flex: 1;
}

.welcome-text h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.welcome-text p {
  font-size: 1.6rem;
  line-height: 1.6;
}

/* Image section */
.welcome-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.welcome-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

/* Responsive for tablets */
@media (max-width: 1024px) {
  .welcome-container {
    flex-direction: column-reverse;
    text-align: center;
    padding: 1.5rem;
  }

  .welcome-text h1 {
    font-size: 2rem;
  }

  .welcome-text p {
    font-size: 1rem;
  }
}

/* Responsive for mobile */
@media (max-width: 768px) {
  .welcome-container {
    padding: 1rem;
  }

  .welcome-text h1 {
    font-size: 1.8rem;
  }

  .welcome-text p {
    font-size: 0.9rem;
  }

  .welcome-image img {
    max-width: 80%;
  }
}
.WeloFour{
  background: linear-gradient(268deg, #b2b2b2 24.39%, #009dff 120.36%);
  border-radius: 10px;
  // height: 100%;
}

// jjj
.features-container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 2rem;
  padding: 0rem 1rem 0.5rem 1rem ;
  max-width: 1200px;
  margin: 0 auto;
  color: white; /* Text color */
}

/* Image Section */
.features-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.features-image img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  object-fit: cover;
}

/* Text Section */
.features-text {
  flex: 2;
  font-size: 1.6rem;
  line-height: 1.6;
}

/* Responsive for tablets */
@media (max-width: 1024px) {
  .features-container {
    flex-direction: column;
    text-align: center;
    padding: 1.5rem;
  }

  .features-text {
    font-size: 1rem;
  }
}

/* Responsive for mobile */
@media (max-width: 768px) {
  .features-container {
    padding: 1rem;
  }

  .features-text {
    font-size: 0.9rem;
  }

  .features-image img {
    max-width: 80%;
  }
}

.Listcolor{
  background: linear-gradient(268deg, #b2b2b2 24.39%, #009dff 120.36%);
 border-radius: 10px;
}
// socre 
.score-container {
  display: flex;
  flex-wrap: wrap;
  gap: 1.5rem;
  padding: 1rem;
  color: white;
  border-radius: 10px;
}

/* Individual Score Box */
.score-box {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 1.5rem;
  background: rgba(255, 255, 255, 0.2); /* Slight transparency */
  border-radius: 10px;
  text-align: center;
  width: 300px;
}

/* Left Side - Card */
.score-card {
  flex: 1;
  font-size: 1.2rem;
  width: 50%;
}

/* Right Side - Pie Chart */
.score-chart {
  flex: 1;
  display: flex;
  justify-content: center;
  width: 50%;
}

/* Responsive for tablets */
@media (max-width: 1024px) {
  .score-container {
    flex-direction: column;
    align-items: center;
  }
}

/* Responsive for mobile */
@media (max-width: 768px) {
  .score-box {
    flex-direction: column;
    text-align: center;
    width: 90%;
  }

  .score-card {
    font-size: 1rem;
  }
}


// card with pie 
.dashboardr-containerr {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 1rem;
  color: white;
  border-radius: 10px;
}

/* Cards Container */
.cardr-containerr {
  display: flex;
  // flex-direction: column;
  gap: 1.5rem;
}

/* Individual Card */
.cardr {
  background: rgba(255, 255, 255, 0.2); /* Transparent White */
  padding: 1.5rem;
  border-radius: 10px;
  text-align: center;
  font-size: 1.2rem;
  width: 250px;
}

/* Pie Chart Container */
.chartr-containerr {
  display: flex;
  justify-content: center;
}

/* Responsive for tablets */
@media (max-width: 1024px) {
  .dashboardr-containerr {
    flex-direction: column;
    align-items: center;
  }
}

/* Responsive for mobile */
@media (max-width: 768px) {
  .cardr {
    width: 90%;
    font-size: 1rem;
  }
}



// list done

.dashboardss-containerss {
  display: flex;
  // align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 1rem;
  color: white;
  border-radius: 10px;
}

/* Cards Container */
.cardss-containerss {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

/* Individual Card */
.cardss {
  background: rgba(255, 255, 255, 0.2);
  padding: 1rem;
  border-radius: 10px;
  font-size: 1.2rem;
  width: 40%;
}

/* Chart Container */
.chartss-containerss {
  width: 500px;
  min-width: 300px;
}

/* Responsive for tablets */
@media (max-width: 1024px) {
  .dashboardss-containerss {
    flex-direction: column;
    align-items: center;
  }

  .chartss-containerss {
    width: 90%;
  }
}

/* Responsive for mobile */
@media (max-width: 768px) {
  .cardss {
    width: 90%;
    font-size: 1rem;
  }

  .chartss-containerss {
    width: 100%;
  }
}


.tabPanelClassName{
  height: 85%;
  overflow-y: auto;
 
  // Scrollbar styling
  &::-webkit-scrollbar {
    display: block !important; // Ensure the scrollbar is displayed
    width: 8px !important; // Customize scrollbar width
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b9b9b9 !important; // Color of the scrollbar thumb
    border-radius: 4px !important; // Rounded corners for the thumb
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #919191 !important; // Darker thumb on hover
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1 !important; // Color of the scrollbar track
  }
}

// REport 
.Reportheading{
  color: white;
  font-size: 20px;
  font-weight: 600;
  padding: 15px;
}