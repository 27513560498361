@use "../../Scss/Inheritance.scss";
@use "../../Scss/Variables.module.scss" as V;

.delete_button {
  width: 100px;
  background-color: V.$error_color;
  color: V.$secondary_color;
  border-radius: 20px;
  height: 35px;
  &:hover {
    color: V.$secondary_color;
    background-color: V.$error_color;
  }
}

.cancel_button {
  @extend %secondary_button;
  width: 100px;
}

.delete_dialog_action {
  @extend %dialog_action;
}

.delete_dialog {
  @extend %dialog;
}

.delete_dialog_title {
  @extend %dialog_title;
}

.delete_dialog_content {
  text-align: center;
  padding-block: 26px;
}

.response_button {
  @extend %primery_button;
  width: 80px;
}
