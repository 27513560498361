@use "../../Scss/Inheritance.scss";
@use "../../Scss/Variables.module.scss" as V;

.form_content {
  @extend %dialog_form;
  margin-top: 7px;
}

.dialog_content {
  @extend %dialog;
}

.instruction-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100vh; // Ensure the container takes full viewport height
  padding: 20px 10px; // Optional: Add some padding
  box-sizing: border-box;
  margin: 50px 8%;

  .instructionheading {
    font-size: 24px;
    margin: 0% 0% 1.5% 0%;
    color: #139c04;
    font-weight: 600;
    border-radius: 5px;
    text-transform: capitalize;
  }

  .instructiondescription {
    font-size: 17px;
    margin: 0% 0% 1.5% 0%;
    color: #8a8787;
    border-radius: 5px;
    // text-transform: capitalize;
  }
  .instructionheadingbold {
    font-size: 15px;
    margin: 0% 0% 1.5% 0%;
    border-radius: 5px;
    font-weight: 500;
    text-transform: capitalize;
  }

  .heading-line {
    border: none;
    border-top: 2px solid #333;
    width: 100%;
    margin-bottom: 1rem;
  }

  .sections-type {
    display: flex;
    font-size: 15px;
    // color: V.$secondary_color;
    flex-direction: column;
    gap: 1rem;
    margin-top: 8px;
  }

  .section-items {
    background-color: #009dff1a;
    border-radius: 10px;
    padding: 8px;
  }
  .centermultiple{
    text-align: center;
    margin-bottom: 10px;
  }

  .instruction-liststo {
    flex: 1; // Allow the list to grow and take available space
    overflow-y: auto; // Enable vertical scrollbar when content overflows
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    // font-size: 23px;
    font-weight: 400;
  }
  .instructionitem {
    display: flex;
    gap: 0.5rem;
    font-size: 15px;
    padding: 2px 10px 5px 20px;
    color: #8a8787;
    width: 100%;
  }

  .proceed-button {
    background-color: #009dff;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 13px;
    font-size: 1.4rem;
    cursor: pointer;
    margin-top: 0px !important;
    width: 19rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #009dff;
    }
  }
}

.Messageloading{
  color: #009dff;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: 10px;
}

.instruction-list-container {
  display: flex;
  gap: 1rem;
}

// Media queries for mobile responsiveness
@media (max-width: 768px) {
  .instruction-container {
    padding: 10px;
  }

  .instructionheading {
    font-size: 1.7rem;
  }

  .instruction-list {
    font-size: 1.2rem;

    .instructionitem {
      font-size: 1.2rem;
      padding: 8px;
    }
  }

  .proceed-button {
    padding: 12px;
    font-size: 1.2rem;
  }
}

// Media queries for smaller tablets and landscape phones
@media (max-width: 480px) {
  .instruction-container {
    padding: 5px;
  }

  .instructionheading {
    font-size: 1.4rem;
  }

  .instruction-list {
    font-size: 1rem;

    .instructionitem {
      font-size: 1rem;
      padding: 6px;
    }
  }

  .proceed-button {
    padding: 10px;
    font-size: 1rem;
  }
}
