.button {
  min-width: 36px;
}

.iconButton {
  color: inherit;
}

.loadingButton {
  min-width: 36px;
}
