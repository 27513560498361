/* Only use  #nnn, #nnnnnn, rgb(), rgba(), hsl(), hsla(), color(). Mui not support other formats */

:root {
  --base-color: #009DFF;
  --secondary-color: #fff;
  --background-color: #f5f5f5;
  --font-color: #000000;
  --success-color: #00b913;
  --error-color: #bd0000;
  --warning-color: #b99000;
  --info-color: #17a2b8;
  --link-color: rgba(36, 32, 209, 1);
}
$primary_color: #009DFF;
$secondary_color: #fff;
$tertiary_color: #000;

// bg and text colors
$background_color: #f5f5f5;
$text_color: #000000;

// semantic colors
$success_color: #00b913;
$error_color: #bd0000;
$warning_color: #b99000;
$info_color: #17a2b8;
$link_color: rgba(36, 32, 209, 1);

//text
$error_font_size: 9.75px;

// mode ( dark or light )
$mode: var(--mode);

// media quary
$phone: 600px;
$tablet: 768px;
$desktop: 1024px;

// Export variables
:export {
  primary: $primary_color;
  secondary: $secondary_color;

  success: $success_color;
  error: $error_color;
  warning: $warning_color;
  info: $info_color;
  link_color: $link_color;
}
