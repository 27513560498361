@use "../../Scss/Inheritance.scss";
@use "../../Scss/Variables.module.scss" as V;

.ok_button {
  width: 116px;
  background-color: V.$error_color;
  color: V.$secondary_color;
  &:hover {
    color: V.$secondary_color;
    background-color: V.$error_color;
  }
}

.session_expired_dialog {
  @extend %dialog;
}

.session_expired_dialog_content {
  text-align: center;
}

.session_expired_dialog_title {
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  text-align: center;
  color: V.$error_color;
}

.session_expired_text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.redirect_text {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}

.session_expired_action {
  @extend %dialog_action;
}

.session_expired_icon {
  width: 100%;
  height: 100%;
  max-width: 290px;
  max-height: 108px;
  margin-block: 8px;
}
