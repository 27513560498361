.tablist_main_component_container{
    width: calc(100% - 0px);
    // width: 100%;
    // display: flex;
    border: 1px solid lightgray;
    border-radius: 8px;
    height: 70%;
   
    @media (max-width:365px) {
        // max-width: 405px;
        width: 114vw;
    }
    @media (min-width:365px) and (max-width:600px) {
        // max-width: 405px;
        width: 95vw;
    }
}
.tablist_main_component_container_vertical{
    width: 100%;
    display: flex;
    border: 1px solid lightgray;
    border-radius: 8px;
    max-height: 400px;
    .MuiTabPanel-root{
        width: 100%;
    }
}
.tablist_header_component_container{
    // width: 100%;
    min-width: 125px;
    display: flex;
    flex-grow: .001;
    border-bottom: 1px solid;
    border-color: lightgray;

}
.tab_container_style{
    border-radius: 8px 8px 0px 0px;
}
.tab_style{
    font-size: 15px;
    font-weight: 600;
    text-transform: capitalize;
}
