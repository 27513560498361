@use "../../Scss/Inheritance.scss";


.Purchased-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // padding: 20px 10px; 
  box-sizing: border-box;
  margin: 50px 0%;
 
}

// Media queries for mobile responsiveness
@media (max-width: 768px) {
  .Purchased-container {
    padding: 10px;
  }
}

// Media queries for smaller tablets and landscape phones
@media (max-width: 480px) {
  .Purchased-container {
    padding: 5px;
  }
}

.Purchased_main{
  display: flex;
  width: 100%;
}
.Purchased_leftside{
  width: 50%;
  background-color: rgb(16, 51, 61);
  color: azure;
  height: 100vh;
}
.Purchased_rightside{
  width: 50%;
  height: 100vh;

}