.onlineexam {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 8% 10px 8%;
  padding: 20px 0px 0px 0px; // Optional: Add some padding
}
.overonlinediv {
  margin: 0px 8% 10px 8%;
  padding: 15px 0px; // Optional: Add some padding
  height: auto !important;
  // overflow: scroll;
  flex: 1;
  // margin-top: 55px !important;
}
.examscroll{
   // Optional: Ensure the container takes full viewport height
  //  height: 500px;
  height: calc(100vh - 330px); // Calculate height dynamically  

  //  padding: 20px 10px; // Add padding
  //  box-sizing: border-box;
  //  margin: 50px 8%;
 
   // Enable scrolling
   overflow-y: auto;
 
   // Scrollbar styling
   &::-webkit-scrollbar {
     display: block !important; // Ensure the scrollbar is displayed
     width: 8px !important; // Customize scrollbar width
   }
 
   &::-webkit-scrollbar-thumb {
     background-color: #b9b9b9 !important; // Color of the scrollbar thumb
     border-radius: 4px !important; // Rounded corners for the thumb
   }
 
   &::-webkit-scrollbar-thumb:hover {
     background-color: #919191 !important; // Darker thumb on hover
   }
 
   &::-webkit-scrollbar-track {
     background: #f1f1f1 !important; // Color of the scrollbar track
   }
}

.onlineexmcount {
  font-size: 15px;
  font-weight: 600;
  line-height: 39px;
  text-align: left;
  color: #ffffff;
}
.onlineexamtime {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  color: #bb3695;
  padding: 7px;
  border-radius: 10px;
  width: 65px;
  text-align: center;
  border-radius: 10px;
}

.onlineexamdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    89.74deg,
    #017cc9 24.39%,
    rgba(0, 157, 255, 0.49) 120.36%
  );
  height: 50px;
  padding: 0px 8%;
  background-color: #fff !important;
  position: fixed !important;
  width: 100% !important;

}
.onlineexamdivs {
  display: flex;
  align-items: center;
  background: linear-gradient(89.74deg, #d9d9d9 24.39%, rgba(0, 157, 255, 0.49) 120.36%);
  height: 50px;
  padding: 0px 8%;
  background-color: #fff !important;
  width: 100% !important;
  margin-top: 50px;
}

.timer {
  text-align: right;
}

.navigation-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .timer {
    text-align: center;
  }
}

.examtext {
  font-size: 21px;
  font-weight: 300 !important;
  line-height: 39px;
  text-align: left;
  text-transform: capitalize;
}

.buttonnamenextsreview {
  font-size: 12px;
  font-weight: 500;
  line-height: 31.25px;
  text-align: left;
  color: #ffffff;
  background: #009dff;
  border-radius: 20px;
  height: 35px;
  text-transform: capitalize;
  width: 200px;
}
.buttonnamenext:hover {
  background: #009dff;
}
.buttonnameprevious {
  font-size: 12px;
  font-weight: 500;
  line-height: 31.25px;
  text-align: left;
  border: 1px solid #009dff;
  color: #009dff;
  border-radius: 20px;
  text-transform: capitalize;
  height: 35px;
  width: 100px;
}

.navigation-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-image: url("../../../Assets/Images/examquestionimage.svg");
  padding: 0px 8% 20px 8%;
  height: 564px;
  z-index: -1; /* Keep the image behind */
}

.navigation-buttons {
  position: sticky !important;
  background-color: #fff;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 5px 8% !important;
  justify-content: space-between; /* Adjusts spacing between buttons */
  z-index: 999; /* Ensure it's above other elements */
  margin: 0 !important;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -1px 3px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.unselectablereviewexam {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  height: calc(100% - 50px);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.Questiontype {
  display: flex;
  align-items: center;
  color: #ffffff;
}
.Questiontypeheading {
  font-size: 18px;
  margin-right: 10px;
  font-weight: 700 !important
}

.buttonsection {
  margin-right: 10px;
  color: white;
  background-color: #bb3695;
  text-transform: capitalize;
  height: 33px;
}

.buttonsection:hover {
  background-color: #bb3695;
}
.boxDialog {
  font-size: 22px;
  font-weight: 500;
  line-height: 39px;
  text-align: left;
  color: #009dff;
}

.boxDialogparapgraph {
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  padding-top: 20px;
}

.listissection {
  padding: 25px;
}
.listtype {
  font-size: 15px;
  font-weight: 500;
  text-align: left;
  line-height: 50px;
}
