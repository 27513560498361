@use "../../Scss/Inheritance.scss";

.notAuthorised {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-content: center;
  justify-content: center;
  align-items: center;
}

.not_authorised_button {
  @extend %primery_button;
}
