@use "../../Scss/Variables.module.scss" as v;

.backGround {
 display: none;
}

.main {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-inline: 24px;
  flex-shrink: 0;
  width: 100%;
  border-radius: 20px;
  // border: 2px solid rgba(119, 154, 249, 0.52);
  backdrop-filter: blur(6px);
  padding: 24px;
  hr {
    background: whitesmoke;
  }
}
.mains{
  display: none;
}
.mainforgotpassword {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-inline: 24px;
  flex-shrink: 0;
  width: 100%;
  border-radius: 20px;
  // border: 2px solid rgba(119, 154, 249, 0.52);
  backdrop-filter: blur(6px);
  padding: 25px;
  hr {
    background: whitesmoke;
  }
}
.mainforgotpasswords {
  display: none;
}
.heading {
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 7px;
  display: flex;
  justify-content: center;
}

.subHeadingforgotpassword {
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  padding: 0px 0px 20px 0px;

}
.subHeading {
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-align: center;
  padding: 20px 0px 20px 0px;
}

.subHeading > a {
  color: #bbc6d8;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
}

.textFields {
  display: flex;
  flex-direction: column;
  gap: 21px;
}

.textField {
  width: 100%;
}

.textField > div {
  border-radius: 5px;
  background: #fff;
  border-color: #eee;
  height: 40px;
}

.buttonfield {
  border-radius: 100px;
  height: 40px;
  background: v.$primary_color;
  width: 100%;
  color: white;
  margin: 20px 0px 20px 0px;
 
}

.buttonfield:hover {
  background-color: v.$primary_color;
}

.buttonfieldregistration {
  border-radius: 100px;
  height: 40px;
  width: 100%;
  border: 1px solid #009dff;
  color: #009dff;

}

.buttonfieldregistration:hover {
  border: 1px solid #009dff;
  color: #009dff;
}
.forgetPassword {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  text-align: end;
}
.forgetPasswordfield {
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  text-decoration: none;
  text-align: center;
}
.login_page {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

/* Style for larger screens */
.parent-div {
  display: flex;
  height: 100%;
  width: 100%;
}

// .child-div1s {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100%;
//   width: 60%;
//   // background: linear-gradient(180deg, #0574e4 0%, #022180 100%);
//   background-image: url(../../Assets/Images/cartoon-ai-robot-scene.jpg);
//   background-position: 10%;
//   background-size: cover;
//   @media (max-width: v.$phone) and (orientation: landscape) {
//     align-items: flex-start;
//     overflow: auto;
//   }
// }

.child-div2s {
  width: 30%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px; /* Optional: Add padding for better spacing */
  box-sizing: border-box; /* Ensure padding does not affect width calculation */

}

/* Responsive adjustments for mobile devices */
@media (max-width: 800px) {
  .parent-div{
    display: none;

  }
  .forgetPassword{
    color: white;
  }
  .backGroundbackground {
    display: flex;
    color: white;
    justify-content: center;
    align-items: center;
    height: 100%;
    // background: linear-gradient(180deg, #0574e4 0%, #022180 100%);
    background-image: url(../../Assets/Images/cartoon-ai-robot-scene.jpg);
    background-position: 10%;
    background-size: cover;
    @media (max-width: v.$phone) and (orientation: landscape) {
      align-items: flex-start;
      overflow: auto;
    }
  }
  .mains {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-inline: 24px;
    flex-shrink: 0;
    width: 100%;
    border-radius: 20px;
    // border: 2px solid rgba(119, 154, 249, 0.52);
    backdrop-filter: blur(6px);
    padding: 24px;
    hr {
      background: whitesmoke;
    }
  }
  .mainforgotpasswords {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-inline: 24px;
    flex-shrink: 0;
    width: 100%;
    border-radius: 20px;
    // border: 2px solid rgba(119, 154, 249, 0.52);
    backdrop-filter: blur(6px);
    padding: 25px;
    hr {
      background: whitesmoke;
    }
  }
 
}

.emailplaceholder{
  margin-bottom: 10px;
}
.passwordplaceholder{
  margin-bottom: 10px;

}

.buttonnamesignup {
  font-size: 12px;
  font-weight: 500;
  line-height: 31.25px;
  text-align: left;
  border: 1px solid #009dff;
  color: #009dff;
  border-radius: 20px;
  text-transform: capitalize;
  height:25px;
  width: 100px;
  margin-left: 10px;
}



.child-div1s {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 70%;
  overflow: hidden;
  
}

.responsive-image {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image fully covers the area */
}