.top_toolbar {
  padding: 14px;
  display: flex;
  justify-content: flex-end;
}
.bottom_toolbar {
  padding: 14px;
  display: flex;
  justify-content: flex-end;
}

.hidden_column {
  width: 0;
  display: none !important;
}
