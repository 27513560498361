.onlineexam {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 50px 0 0 0;
  padding: 0; // Optional: Add some padding
}
.overonlinediv {
  margin: 0px 8% 10px 8%;
  padding: 15px 0px; // Optional: Add some padding
  height: 600px;
  // overflow: scroll;
  
}
/* Add this CSS to your styles */
.custom-quill .ql-editor {
    // Enable scrolling
    overflow-y: auto;
    text-decoration: none !important; /* Remove underlines */

    // Scrollbar styling
    &::-webkit-scrollbar {
      display: block !important; // Ensure the scrollbar is displayed
      width: 8px !important; // Customize scrollbar width
    }
  
    &::-webkit-scrollbar-thumb {
      background-color: #b9b9b9 !important; // Color of the scrollbar thumb
      border-radius: 4px !important; // Rounded corners for the thumb
    }
  
    &::-webkit-scrollbar-thumb:hover {
      background-color: #919191 !important; // Darker thumb on hover
    }
  
    &::-webkit-scrollbar-track {
      background: #f1f1f1 !important; // Color of the scrollbar track
    }
 }

 .examscrollreading{
  // Optional: Ensure the container takes full viewport height
 //  height: 500px;
 height: 100%; // Calculate height dynamically

 //  padding: 20px 10px; // Add padding
 //  box-sizing: border-box;
 //  margin: 50px 8%;

  // Enable scrolling
  overflow-y: auto;

  // Scrollbar styling
  &::-webkit-scrollbar {
    display: block !important; // Ensure the scrollbar is displayed
    width: 8px !important; // Customize scrollbar width
  }

  &::-webkit-scrollbar-thumb {
    background-color: #b9b9b9 !important; // Color of the scrollbar thumb
    border-radius: 4px !important; // Rounded corners for the thumb
  }

  &::-webkit-scrollbar-thumb:hover {
    background-color: #919191 !important; // Darker thumb on hover
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1 !important; // Color of the scrollbar track
  }
}

.onlineexmcount {
  font-size: 17px;
  font-weight: 600;
  line-height: 39px;
  text-align: left;
  color: #ffffff;
}
.onlineexamtime {
  background-color: #ffffff;
  border: 1px solid #dddddd;
  color: #bb3695;
  padding: 7px;
  border-radius: 10px;
  width: 65px;
  text-align: center;
  border-radius: 10px;
}

.onlineexamdiv {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(
    89.74deg,
    #017cc9 24.39%,
    rgba(0, 157, 255, 0.49) 120.36%
  );
  height: 50px;
  padding: 0px 8%;
}
.timer {
  text-align: right;
}

.navigation-buttons {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}

@media (max-width: 768px) {
  .timer {
    text-align: center;
  }
}

.examtext {
  font-size: 22px;
  font-weight: 500;
  line-height: 39px;
  text-align: left;
  text-transform: capitalize;
}

.buttonnamenext {
  font-size: 14px;
  font-weight: 500;
  line-height: 31.25px;
  text-align: left;
  color: #ffffff;
  background: #009dff;
  border-radius: 20px;
  height: 35px;
  text-transform: capitalize;
  width: 100px;
}
.buttonnamenext:hover {
  background: #009dff;
}
.buttonnameprevious {
  font-size: 14px;
  font-weight: 500;
  line-height: 31.25px;
  text-align: left;
  border: 1px solid #009dff;
  color: #009dff;
  border-radius: 20px;
  text-transform: capitalize;
  height: 35px;
  width: 100px;
}

.navigation-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-image: url("../../Assets/Images/examquestionimage.svg");
  padding: 0px 8% 20px 8%;
  height: 564px;
  z-index: -1; /* Keep the image behind */
}

.navigation-buttons {
  position: fixed; /* or absolute if within a container */
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  padding: 0px 8% 20px 8%;
  justify-content: space-between; /* Adjusts spacing between buttons */
  z-index: 999; /* Ensure it's above other elements */
}

.unselectable {
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.Questiontype {
  display: flex;
  align-items: center;
  color: #ffffff;
}
.Questiontypeheading {
  font-size: 20px;
  margin-right: 10px;
}

.buttonsection {
  margin-right: 10px;
  color: white;
  background-color: #bb3695;
  text-transform: capitalize;
  height: 33px;
}

.buttonsection:hover {
  background-color: #bb3695;
}
.boxDialog {
  font-size: 24px;
  font-weight: 500;
  line-height: 39px;
  text-align: left;
  color: #009dff;
}

.boxDialogparapgraph {
  font-size: 17px;
  font-weight: 500;
  text-align: left;
  padding-top: 20px;
}

.listissection {
  padding: 25px;
}
.listtype {
  font-size: 17px;
  font-weight: 500;
  text-align: left;
  line-height: 50px;
}
