@use "../../Scss/Inheritance.scss";

.form_content {
  @extend %dialog_form;
  margin-top: 7px;
}

.dialog_content {
  @extend %dialog;
}

.instruction-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // height: 100vh; // Ensure the container takes full viewport height
  padding: 20px 10px; // Optional: Add some padding
  box-sizing: border-box;
  margin: 50px 8%;

  .instruction-heading {
    font-size: 25px;
    margin: 0% 0% 1.5% 0%;
    color: #037ECB;
    font-weight: 600;
    border: 1px solid #E2E2E2;
    border-radius: 5px;
    padding: 10px;
    text-transform: capitalize;
  }

  .heading-line {
    border: none;
    border-top: 2px solid #333;
    width: 100%;
    margin-bottom: 1rem;
  }

  .instruction-list {
    flex: 1; // Allow the list to grow and take available space
    overflow-y: auto; // Enable vertical scrollbar when content overflows
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    font-size: 25px;
    font-weight: 400;

    .instruction-item {
      display: flex;
      gap: 0.5rem;
      font-size: 1.27rem;
      background-color: #f5f5f5;
      border-radius: 5px;
      padding: 10px;
      color: #1f1f1f;
      margin: 0.5% 0%;
      width: 100%;

      .icon {
        color: #ffd700; // Gold color for the star icon
      }
    }
  }

  .proceed-button {
    background-color: #009DFF;
    color: white;
    border: none;
    border-radius: 30px;
    padding: 13px;
    font-size: 1.4rem;
    cursor: pointer;
    margin-top: 20px;
    width: 14rem;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: #009DFF;
    }
  }
}

// Media queries for mobile responsiveness
@media (max-width: 768px) {
  .instruction-container {
    padding: 10px;
  }

  .instruction-heading {
    font-size: 1.7rem;
  }

  .instruction-list {
    font-size: 1.2rem;

    .instruction-item {
      font-size: 1.2rem;
      padding: 8px;
    }
  }

  .proceed-button {
    padding: 12px;
    font-size: 1.2rem;
  }
}

// Media queries for smaller tablets and landscape phones
@media (max-width: 480px) {
  .instruction-container {
    padding: 5px;
  }

  .instruction-heading {
    font-size: 1.4rem;
  }

  .instruction-list {
    font-size: 1rem;

    .instruction-item {
      font-size: 1rem;
      padding: 6px;
    }
  }

  .proceed-button {
    padding: 10px;
    font-size: 1rem;
  }
}
