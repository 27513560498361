.dashboard_container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  overflow: auto;
}

.dashboard_cards_container {
  display: grid;
  gap: 8px;
  padding: 8px 0px;
  grid-auto-rows: 1fr;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  overflow: clip;
}

.dashboard_cards {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  // padding: 8px;
  // min-height: 240px;
}
.dashboard_cardspending {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 8px;
  min-height: 140px;
  width: 32%;
}

.score-boxss {
  
  padding: 0.5rem 1.5rem 1.5rem 1.5rem;
  background:#4f6370; /* Slight transparency */
  border-radius: 10px;
  text-align: center;
  width: 400px;

}
.score-boxsslist {
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 10px;
  text-align: center;
  height: 10rem;

}


/* Cards Container */
.cardr-cont {
  display: flex;
  // flex-direction: column;
  gap: 1.5rem;
}
@media (max-width: 1099px) {
  .cardr-cont {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }

}
.cardrandlist {
  background:#989b9c ; /* Transparent White */
  padding: 1rem;
  border-radius: 10px;
  text-align: left;
  font-size: 1.2rem;
  // width: 250px;
}
.cardrandlisteded {
  background:#a8b9bf; ; /* Transparent White */
  padding: 1rem;
  border-radius: 10px;
  text-align: left;
  font-size: 1.2rem;

  // width: 250px;
}
.cardrandlistss {
  background:#83a5b0  ; /* Transparent White */
  padding: 1rem;
  border-radius: 10px;
  text-align: left;
  font-size: 1.2rem;
  // width: 250px;
}

.count_no {
  font-size: 2.5rem;
  font-weight: 700;
}


@media (max-width: 958px) { /* Adjust the width as per your needs */
  .dashbordflex {
    flex-wrap: wrap;
    display: flex;
    gap: 2;
  }
}

@media (max-width:760px){
  .dashboardfont{
    font-size: 15px;
  }
}

.overexam{
  font-size: 20px;
  color: #4779ff;
  padding: 20px 20px 20px 0px;
  font-weight: 600;
}