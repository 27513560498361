.TotalExamListmain{
  height: 100vh;
  margin: 5px 8%;
}
/* Container for the entire dashboard section */
.dashboard_container {
  padding: 40px;
  background-color: #f9f9f9;
  text-align: center;
}


.customCardpendingtotal{
  width: 100%;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  // height: 14.5rem;
  border: 1px solid #f4f4f4;
  padding: 10px;
  border-radius: 10px;


}

/* Dashboard heading */
.dashboard_heading {
  font-size: 2.7rem;
  margin-bottom: 30px;
  color: #333;
}

/* Flex container for the cards */
.ExamList_cards_container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Creates a responsive grid with a minimum card width */
  gap: 16px; /* Space between cards */
  padding: 10px 0; /* Optional padding */
}

/* Individual card styling */
.ExamList_card {
  flex: 1 1 calc(50% - 16px); /* Two cards per row with gap consideration */
  min-width: 200px; /* Ensures cards don't get too small */
  background-color: #ffffff;
  border: 1px solid #CBCBCB;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  // box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: center;
}
.heading_nameExamlist {
  font-size: 1.27rem;
  color: white;
  font-weight: 600;
  background-color: #55a7ff;
  width: 95%;
  height: 105px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
  background-image: url(../../Assets/Images/Card.svg);
  background-size: cover;
  padding-left: 10px;

}
/* Hover effect for cards */
.ExamList_card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

/* Card title */
.ExamList_card h2 {
  font-size: 1.7rem;
  margin-bottom: 10px;
  color: #555;
}

/* Card description */
.ExamList_card p {
  font-size: 1.2rem;
  color: #777;
}

/* Responsive Design */
@media (max-width: 768px) {
  .ExamList_cards_container {
    flex-wrap: wrap; /* Allow wrapping on smaller screens */
    display: flex;
    gap: 16px; /* Space between cards */
    justify-content: center; /* Center the cards horizontally */
  
  }

  // .ExamList_card {
  //   flex: 1 1 100%; /* Stack cards vertically on smaller screens */
  //   max-width: 400px; /* Optional: limit max width for better readability */
  // }
}

.courseslist{
  font-size: 24px;
  font-weight: 500;
  padding: 15px 0px 15px 0px;
  color: #292828;
  ;
}
.customCard {
  width: 100%;
  background: linear-gradient(#008FE8,#009DFF);
  padding: 20px;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 12.5rem;
  border-radius: 10px;
}
.customCardpendingexam {
  width: 100%;
  padding: 20px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  height: 14.5rem;
  border-radius: 10px;
  border: 1px solid #CBCBCB
}

.images {
  width: 150px;
  height: 140px;
  object-fit: cover;
  margin-top:-27px;
}


.image {
  width: 120px;
  height: 121px;
  object-fit: cover;
}
.textContentexam{
  margin-left: 35px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
  width:100%
}
.textsytleexam{
  font-size: 15px;
  font-weight: 400;
}
.textsytleheadingexam{
  font-size: 18px;
  font-weight: 600;
  padding: 10px 0px 10px 0px;
}


// card 
.mock-exam-container {
  text-align: center;
  padding: 20px 0px 10px 0px;
  width: 100%;
}

.mock-exam-heading {
  font-size: 2.2rem;
  margin-bottom: 30px;
  color: #333;
  text-align: left;
}

.cards-wrapper {
  display: flex;
  justify-content: center; /* Center the cards horizontally */
  gap: 20px;   
  width: 100%;            /* Space between the cards */
}

.card {
  background-color: #fbfbfb;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  width: 100%;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 12px 20px rgba(0, 0, 0, 0.2);
}

.card h2 {
  font-size: 2rem;
  margin-bottom: 15px;
  color: #555;
}

.card p {
  font-size: 2rem;
  color: #777;
}

.card-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 0px;
}

.card-button:hover {
  background-color: #0056b3;
}

.card-buttonsExam {
  padding: 10px 20px;
  background-color: #E7E7E7;
  color: #000000;
  border: none;
  border-radius: 5px;
  
  transition: background-color 0.3s ease;
  margin: 10px 0px;
}

.card-button:hover {
  background-color: #0056b3;
  color: #fff;
}
.desudrised{
  width: 100%;
    text-align: left;
    margin-left: 10px;

}

.card-buttonlistexam {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin: 10px 0px;
  width: 95%;
}

.card-buttonlistexam:hover {
  background-color: #0056b3;
}

.concentnameExam{
  text-align: left;
  width: 100%;
  padding: 10px 10px;
  font-size: larger;
  text-transform: capitalize;
  font-weight: 600;
}
.examcountlist{
  text-align: left;
  width: 100%;
  padding: 10px 10px 0px 10px;
  font-size: larger;
  text-transform: capitalize;
  color: #635151;
}

.costoflistexamexam{
  text-align: left;
  width: 100%;
  padding: 4px 10px 1px 10px;
  font-size: larger;
  text-transform: capitalize;
  font-weight: 600;
}

.couresliststyle{
  font-size: 19px;
  text-transform: capitalize;
  color: #0088ff;

}

.Myenrollments{
  background-color: #0088ff;
  padding: 30px 8%;
  box-sizing: border-box;
  margin: 50px 0%  0px 0%;
  color: white;
  font-size: 27px;
  font-weight: 600;
}
.examslist{
font-size: 17px;
color: #0088ff;

}

.Buttonlist{
  display: flex;
  gap: 10px;
}